<template>
  <div id="xunZheng">
    <!--头部导航-->
    <div style="z-index:9999;position:fixed;top:0;left: 0;">
      <baseNav></baseNav>
    </div>
    <!--内容-->
    <div class="xunZhengCon">
      <!--内容头部-->
      <div class="commenHead">
        <div class="about_tit">
          <p  v-if="xunzhengData.title&&xunzhengData.title.length>0"
              class="animated bounceInLeft"
              v-html="xunzhengData.title[0].title"
              style="width: 70vw;"
          ></p>
        </div>
        <div class="about_img">
          <div class="right_grid ">
            <img src="../assets/images/pic_grid_r.png" alt="">
          </div>
          <div class="left_grid " style="height: auto;">
            <img src="../assets/images/pic_grid_l.png" alt="">
          </div>
        </div>
      </div>

<!--      循证内容-->
      <div class="xunzhengBox" >
        <div class="xunZhengItems" v-show="xunZhengList&&xunZhengList.length>0">
          <div class="items" v-for="(item,index) in xunZhengList" :key="item.id">
            <div class="item_tit">{{ item.title }}</div>
            <div class="item_con">
              {{item.desc}}
            </div>
            <div class="btns">
              <div class="btn"  :class="{btn_p:index_l==index}" >
                <v-touch @press="press(index,'l')" @pressup="pressup(item.id,index,'l')" @tap="tap(item.id,index,'l')">
                  <div>
                    <a>Interpretation</a>
                  </div>

                </v-touch>
              </div>
              <div class="btn"  :class="{btn_p:index_r==index}" >
                <v-touch @press="press(index,'r')" @pressup="pressup(item.files_files,index,'r')" @tap="tap(item.files_files,index,'r')">
                  <a target="_blank">Download</a>
                </v-touch>
              </div>
            </div>

          </div>
        </div>
        <div style="color:#fff;opacity: 0.8" v-show="xunZhengList&&xunZhengList.length==0">暂无数据</div>
      </div>

    </div>
    <div v-show="xunZhengList&&xunZhengList.length>0">
          <Copyright></Copyright>
    </div>
    <div style="width: 100%;position: fixed;bottom: 0; left: 50%;transform: translateX(-50%)" v-show="xunZhengList&&xunZhengList.length==0">
      <Copyright></Copyright>
    </div>
  </div>
</template>

<script>
import baseNav from "./baseNav";
import Copyright from "@/component/Copyright";
import {GetXunzhengData,GetIndexDoc} from "@/api/api";
export default {
  data(){
    return{
      //询证banner
      xunzhengData:{},
      //当前列表
      xunZhengList:[],
      index_l:9999,
      index_r:9999,
    }
  },
  mounted() {
    //获取询证页面banner数据
    this.toGetIndexDoc(7)
    this.toGetXunzhengData()
  },
  methods:{
    //获取询证页面banner数据
    toGetIndexDoc(data){
      let _this=this
      GetIndexDoc(data)
          .then(r=>{
            let res=r.data
            if(res.status!=1){
              _this.xunzhengData=[]
              return
            }
            let apiIndex=res.data
            _this.xunzhengData=apiIndex
          })
    },
    //获取询证页面数据
    toGetXunzhengData(){
      let _this=this
      GetXunzhengData(_this.page)
          .then(r=>{
            let res=r.data
            if(res.status!=1){
              _this.xunZhengList=[]
              return
            }
            _this.xunZhengList=res.data
            console.log('--------------')
            console.log(res.data)
          })
    },
    //点击按住
    press(n,x){
      if(x=='l'){//左侧阅读文章按钮
        this.index_l=n
      }else if(x=='r'){//右侧下载视频按钮
        this.index_r=n
      }
    },
    //按住后抬起
    pressup(item,n,x){
      if(x=='l'){//左侧阅读文章按钮
        this.index_l=9999
        this.index_r=9999
        //  跳转至详情页
        this.$router.push({path:'/index/doc/illDocDetail',query:{id:item}})
      }else if(x=='r'){//右侧下载视频按钮
        this.index_l=9999
        this.index_r=9999
        //跳转至下载页面
        window.location.href = item
      }
    },
    // 点击
    tap(item,n,x){
      let _this=this
      if(x=='l'){//左侧阅读文章按钮
        _this.index_l=n
        //  跳转至详情页
        setTimeout(function (){
          _this.index_l=9999
          //跳转至下载页面
          _this.$router.push({path:'/index/doc/illDocDetail',query:{id:item}})
        },100)

      }else if(x=='r'){//右侧下载视频按钮
        _this.index_r=n
        //跳转至下载页面
        setTimeout(function (){
          _this.index_r=9999
          //跳转至下载页面
          window.location.href = item
        },100)
      }

    }

  },
  components:{
    baseNav,
    Copyright
  }
}
</script>
<style lang="less" scoped>
#xunZheng{
    padding-top: 13.888vw;
    background-color: #071D42;
    min-height: calc(100vh - 13.888vw);
    user-select:none;
  }
.xunZhengCon{
  .commenHead{
    background:url("../assets/images/pic_banner_zhengxun.png") no-repeat 77% top;
    background-size: 156%;
  }
  //循证内容
  .xunzhengBox{
    margin:0 6.666vw 16.666vw;

    .xunZhengItems{

      .items{
        padding:5.5555vw 4.444vw 3.3333vw;
        background: rgba(32, 191, 208, 0.1);
        border-radius: 1.1111vw;
        margin-bottom:3.2vw;
        .item_tit{
          font-size: 3.333vw;
          font-weight: 500;
          line-height: 4.0277vw;
          margin-bottom:4.166vw;
          color:#fff;
          font-family: Montserrat-Medium, Montserrat;
        }
        .item_con{
          //font-size: 2.777vw;
          font-size: 3.333vw;
          font-weight: 300;
          line-height: 4.305vw;
          color:#fff;
          opacity: .8;
          // font-family:  Montserrat;
          //display: -webkit-box;
          //-webkit-box-orient: vertical;
          //-webkit-line-clamp:4;
          //overflow: hidden;
        }
        .btns{
          margin-top:8.194vw;
          display: flex;
          justify-content: space-between;
          text-align: center;
          line-height: 8.333vw;
          .btn{
            width: 37vw;
            height: 8.333vw;
            font-size: 3.333vw;
            border-radius: 0.5555vw;
            color:#20BFD0;
            background-color: rgba(32, 191, 208, 0.2);
            border: 0.138vw solid #20BFD0;
            box-sizing: border-box;
            font-family: Montserrat-Regular, Montserrat;
            a{
              user-select:none;
              color:#20BFD0;
            }
          }
          .btn_p{
            background: #20BFD0;
            a{
              color:#071D42;
            }
          }
        }
      }


    }
  }

}
</style>